import { Injectable } from '@angular/core';
import { ApiService, IdentityService, NavigationService, TokenService } from '../../../core/services';
import { Router } from '@angular/router';
import { IIdentity, ILogin, IResetPassword, ISetNewPassword } from '../interfaces';
import { Observable } from 'rxjs';
import { LocalStorageService } from '@cinetixx/cinetixx-ui';
import { LanguageStoreService } from '../../../language/core/services';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly _basePath = '/employees';

  public constructor(
    private readonly _apiService: ApiService,
    private readonly _tokenService: TokenService,
    private readonly _identityService: IdentityService,
    private readonly _router: Router,
    private readonly _localStorageService: LocalStorageService,
    private readonly _languageStoreService: LanguageStoreService,
    private readonly _navigationService: NavigationService,
  ) {}

  public login(body: ILogin): Observable<IIdentity> {
    return this._apiService.post(`${ this._basePath }/login`, body);
  }

  public getStatus(): Observable<string> {
    return this._apiService.get(`${ this._basePath }/status`);
  }

  public refreshToken(): Observable<IIdentity> {
    return this._apiService.post(
      `${ this._basePath }/refresh-token`,
      {
        refreshToken: this._tokenService.refreshToken
      }
    );
  }

  public revokeTokens(): void {
    this._apiService.post(
      `${ this._basePath }/logout`,
      {
        refreshToken: this._tokenService.refreshToken
      }
    ).subscribe();
  }

  public resetPassword(data: IResetPassword): Observable<void> {
    return this._apiService.post(`${this._basePath}/reset-password`, data);
  }

  public setPassword(data: ISetNewPassword): Observable<void> {
    return this._apiService.post(`${this._basePath}/set-password`, data);
  }

  public purgeAuth(withRedirect = false): void {
    if (this._identityService.identity) {
      this.revokeTokens();
    }

    this._identityService.destroyIdentity();
    this._localStorageService.clear();

    if (withRedirect) {
      this._router.navigateByUrl('/auth/login'.langUrl());
    }
  }

  public authWithToken(token): Observable<IIdentity> {
    return this._apiService.post(
      `${ this._basePath }/login-with-token`,
      {
        token,
      }
    );
  }

  public logout(): void {
    this._identityService.destroyIdentity();
    this._navigationService.navigateByUrl(
      `/auth/login?back=${ location.pathname.replace(`/${ this._languageStoreService.language.token }`, '') }`
    );
  }
}
