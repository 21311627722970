import { Guid } from 'guid-typescript';
import { inject } from '@angular/core';
import { ApiService } from './ApiService';

export abstract class AbstractWsService {

  protected readonly apiService = inject(ApiService);

  private _operationIds: string[] = [];

  public isOperationIdInArray(operationId: string): boolean {
    return this._operationIds.includes(operationId);
  }

  public removeOperationIdFromArray(operationId: string): void {
    this._operationIds = this._operationIds.filter(x => x !== operationId);
  }

  public prepareGuid(): string {
    const operationId = Guid.raw();

    this.addOperationId(operationId);

    return operationId;
  }

  protected addOperationId(operationId: string): void {
    this._operationIds.push(operationId);
  }
}
