import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { IdentityService } from '../services';

@Directive({
  selector: '[appShowAuthed]'
})
export class ShowAuthedDirective implements OnInit {

  @Input() set appShowAuthed(condition: boolean) {
    this._condition = condition;
  };

  public constructor(
    private readonly _templateRef: TemplateRef<any>,
    private readonly _identityService: IdentityService,
    private readonly _viewContainer: ViewContainerRef
  ) {}

  private _condition: boolean;
  private _isCreated = false;

  public ngOnInit(): void {
    if (
      this._identityService.isAuthenticated &&
      this._condition &&
      !this._isCreated ||
      !this._identityService.isAuthenticated &&
      !this._condition &&
      !this._isCreated
    ) {
      this._isCreated = true;
      this._viewContainer.createEmbeddedView(this._templateRef);
    } else if (
      this._identityService.isAuthenticated &&
      !this._condition ||
      !this._identityService.isAuthenticated &&
      this._condition
    ) {
      this._viewContainer.clear();
      this._isCreated = false;
    }
  }
}
