import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpRequestInterceptor, HttpTokenInterceptor } from './interceptors';
import {
  DateFormatPipe,
  DateTimeFormatPipe,
  DictionaryValuePipe,
  FilterPipe,
  IncludesPipe, IsSameOrBeforePipe, IssetPipe, MinutesToTimePipe,
  NumberToEuroPipe,
  NumberToNotationPipe,
  NumberToPercentPipe,
  PercentagePipe,
  ReplacePipe,
  StringPipe,
  TimeFormatPipe,
  TimestampPipe,
  UrlTrailerPipe
} from './pipes';
import { AreaSelectDirective, PreloadImageDirective, ShowAuthedDirective, ShowForRoleDirective } from './directives';
import { PlanningScalingButtonsComponent } from './ui/planning-scaling-buttons/planning-scaling-buttons.component';
import { LanguageModule } from '../language/language.module';
import { TooltipModule } from '@cinetixx/cinetixx-ui';

@NgModule({
  declarations: [
    NumberToNotationPipe,
    DateTimeFormatPipe,
    DateFormatPipe,
    TimeFormatPipe,
    NumberToEuroPipe,
    ReplacePipe,
    ShowForRoleDirective,
    PreloadImageDirective,
    PreloadImageDirective,
    ShowAuthedDirective,
    StringPipe,
    IncludesPipe,
    UrlTrailerPipe,
    PercentagePipe,
    FilterPipe,
    DictionaryValuePipe,
    AreaSelectDirective,
    NumberToPercentPipe,
    TimestampPipe,
    PlanningScalingButtonsComponent,
    IsSameOrBeforePipe,
    MinutesToTimePipe,
    IssetPipe
  ],
  imports: [
    CommonModule,
    LanguageModule,
    TooltipModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInterceptor,
      multi: true
    }
  ],
  exports: [
    DateTimeFormatPipe,
    DateFormatPipe,
    NumberToEuroPipe,
    TimeFormatPipe,
    ReplacePipe,
    ShowForRoleDirective,
    PreloadImageDirective,
    ShowAuthedDirective,
    StringPipe,
    IncludesPipe,
    FilterPipe,
    UrlTrailerPipe,
    PercentagePipe,
    DictionaryValuePipe,
    AreaSelectDirective,
    NumberToPercentPipe,
    TimestampPipe,
    NumberToNotationPipe,
    PlanningScalingButtonsComponent,
    IsSameOrBeforePipe,
    MinutesToTimePipe,
    IssetPipe
  ]
})
export class CoreModule { }
