import { Observable } from 'rxjs';
import { StaticDependenciesService } from '../services';
import { StoreCacheKeys } from '../enums';

type Descriptor<T> = TypedPropertyDescriptor<(...args: any[]) => Observable<T>>;

export function ReloadCache<T>(key: StoreCacheKeys): (target: object, propertyKey: string, descriptor: Descriptor<T>) => Descriptor<T> {

  return (target: object, propertyKey: string, descriptor: Descriptor<T>): Descriptor<T> => {

    const modifiedPropertyKey = key + 'Cache$';
    const originalMethod: (...args: any[]) => Observable<T> = descriptor.value!;

    descriptor.value = function(...args: any[]): Observable<T> {

      const storeService = StaticDependenciesService.storeService;

      if (storeService.constructor.prototype[modifiedPropertyKey]) {
        delete storeService.constructor.prototype[modifiedPropertyKey];
      }

      return originalMethod.call(this, ...args);
    };

    return descriptor;
  };
}
