import { Injectable } from '@angular/core';
import { StoreService } from './StoreService';
import { SnippetsStoreService, LanguageStoreService, SnippetsService } from '../../language/core/services';
import { TokenService } from './TokenService';
import { IdentityService } from './IdentityService';

@Injectable()
export class StaticDependenciesService {

  private static _storeService: StoreService;
  private static _snippetStoreService: SnippetsStoreService;
  private static _snippetsService: SnippetsService;
  private static _languageStoreService: LanguageStoreService;
  private static _tokenService: TokenService;
  private static _identityService: IdentityService;

  constructor(
    private readonly _storeService: StoreService,
    private readonly _snippetStoreService: SnippetsStoreService,
    private readonly _snippetsService: SnippetsService,
    private readonly _languageStoreService: LanguageStoreService,
    private readonly _tokenService: TokenService,
    private readonly _identityService: IdentityService,
  ) {
    StaticDependenciesService._storeService = _storeService;
    StaticDependenciesService._snippetStoreService = _snippetStoreService;
    StaticDependenciesService._snippetsService = _snippetsService;
    StaticDependenciesService._languageStoreService = _languageStoreService;
    StaticDependenciesService._tokenService = _tokenService;
    StaticDependenciesService._identityService = _identityService;
  }

  public static get storeService(): StoreService {
    return StaticDependenciesService.getService<StoreService>(StaticDependenciesService._storeService);
  }

  public static get snippetsStoreService(): SnippetsStoreService {
    return StaticDependenciesService.getService<SnippetsStoreService>(StaticDependenciesService._snippetStoreService);
  }

  public static get snippetsService(): SnippetsService {
    return StaticDependenciesService.getService<SnippetsService>(StaticDependenciesService._snippetsService);
  }

  public static get languageStoreService(): LanguageStoreService {
    return StaticDependenciesService.getService<LanguageStoreService>(StaticDependenciesService._languageStoreService);
  }

  public static get tokenService(): TokenService {
    return StaticDependenciesService.getService<TokenService>(StaticDependenciesService._tokenService);
  }

  public static get identityService(): IdentityService {
    return StaticDependenciesService.getService<IdentityService>(StaticDependenciesService._identityService);
  }

  private static getService<T>(service: T): T {
    if (!service) {
      throw new Error('StaticDependenciesService in not initialized.');
    }

    return service;
  }
}
