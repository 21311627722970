import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { NavigationService, IdentityService } from '../services';

export const AuthGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
  if (!inject(IdentityService).identity) {
    const parts = state.url.split('/');

    inject(NavigationService).navigateByUrl(`/auth/login?back=/${ parts.slice(2, parts.length).join('/') }`);

    return false;
  } else {
    return true;
  }
};
