import { Apps } from '../app/core/enums';

export const environment = {
  production: true,
  app: Apps.Manager,
  isSW: false,
  brand: 'CX',
  apiUrl: 'https://api.backend.cinetixx.com',
  oldApiUrl: 'https://api.cinetixx.com/v1', //It was implemented for logas to log in to manager as selected user, I have had to generate tocken from old API
  cloudFrontUrl: 'https://images.cinetixx.com',
  screenerAssetsUrl: 'https://screener-assets.cinetixx.com',
  adminUrl: 'https://admin.cinetixx.de',
  appUrl: 'https://manager.cinetixx.de',
  fallbackLanguage: 'de',
  movieDataBaseResultsPage: 50,
  servicesDateFormat: 'YYYY-MM-DD',
  servicesDateTimeFormat: 'YYYY-MM-DD HH:mm',
  defaultDateFormat: 'dd.MM.yyyy',
  defaultTimeFormat: 'HH:mm',
  version: 'v4.18.6',
  wsUrl: 'https://api.backend.cinetixx.com/signalr',
  oldWsUrl: 'https://ws.cinetixx.com',
  versionPrefix: '',
  dsn: "https://17498468f60240b1b1f516f5dc85aac8@sentry.cinetixx.com/23",
  screenerUrl: 'https://tube.cinetixx.de',
  screenerAwsLink: 'https://screener-assets.cinetixx.com',
  postersLink: 'https://images.cinetixx.com/posters',
};
