import { Injectable } from '@angular/core';
import { ExportDocumentTypes, ITableExportStrategy } from '@cinetixx/cinetixx-ui';
import { TableExportTypes } from '../enums';
import { Observable } from 'rxjs';
import { ExportsService } from '../services';


@Injectable({
  providedIn: 'root'
})
export class TableExportStrategy<T> implements ITableExportStrategy<T> {

  public constructor(
    private readonly _exportsService: ExportsService
  ) {}

  public export(
    properties: Record<string, string>,
    columns: Record<string, string>,
    exportType: TableExportTypes,
    outputTypes: ExportDocumentTypes,
    fileName?: string
  ): Observable<void> {
    return this._exportsService.exportTable(properties, columns, exportType, outputTypes, fileName);
  }
}
