export enum TableExportTypes {
  AuditoriumsOccupancy = 'AuditoriumsOccupancy',
  MoviesRequests = 'MoviesRequests',
  ScreenerStreams = 'ScreenerStreams',
  ScreenerDevices = 'ScreenerDevices',
  ScreenerDeviceLogs = 'ScreenerDeviceLogs',
  Reports = 'Reports',
  TbDataReports = 'TbDataReports',
  GutschriftReports = 'GutschriftReports',
  Ffa = 'Ffa',
  RawDataReports = 'RawDataReports',
  Campaigns = 'Campaigns',
  CampaignsStatistics = 'CampaignsStatistics',
  ProductsByNumber = 'ProductsByNumber',
  GroupedVouchers = 'GroupedVouchers',
  GroupedCards = 'GroupedCards',
  Vouchers = 'Vouchers',
  Cards = 'Cards',
  VoucherStatusHistory = 'VoucherStatusHistory',
  CardActionsHistory = 'CardActionsHistory',
  VoucherTransactions = 'VoucherTransactions',
  CardTransactions = 'CardTransactions',
  CardBonusHistory = 'CardBonusHistory',
  BonusPrograms = 'BonusPrograms',
  CinemasAuditoriums = 'CinemasAuditoriums',
  Subscribers = 'Subscribers',
  Customers = 'Customers',
  Cinemas = 'Cinemas',
  Employees = 'Employees',
  ScannerDevices = 'ScannerDevices',
  TechnicalDevices = 'TechnicalDevices',
  ReleaseNotes = 'ReleaseNotes',
  ActivationOrders = 'ActivationOrders',
  Invoices = 'Invoices',
  SubscribersStatistics = 'SubscribersStatistics',
  ClickDetailsTableComponent = 'ClickDetailsTableComponent',
  TbConditionsTableComponent = 'TbConditionsTableComponent',
  TbMoviesRowTableComponent = 'TbMoviesRowTableComponent',
  BouncedEmails = 'BouncedEmails',
  TicketDetails = 'TicketDetails',
  CustomerActions = 'CustomerActions',
  CustomerTransactions = 'CustomerTransactions',
  CustomerCards = 'CustomerCards',
  CustomerLogs = 'CustomerLogs',
  Stock = 'Stock',
  KioskActions = 'KioskActions',
  KioskProducts = 'KioskProducts',
  KioskSets = 'KioskSets',
  MandatorLayouts = 'MandatorLayouts',
}
