export enum Apps {
  Manager = 'Manager',
  Admin = 'Admin'
}

export enum AppsNames {
  Manager = "Cinetixx Manager",
  Admin = "Cinetixx Admin"
}

export enum ShortAppsNames {
  Manager = "Manager",
  Admin = "Admin"
}
