import { Injectable } from '@angular/core';
import { AbstractSignalRService } from '../../../core/services';
import { ExportsWsMethods } from '../enums';
import { ExportsWsPayload } from '../types';

@Injectable({
  providedIn: 'root'
})
export class ExportsWsService extends AbstractSignalRService {

  public constructor() {
    super('/exports');
  }

  public onExportUploaded(method: (payload: ExportsWsPayload) => void): void {
    this.createMethod(ExportsWsMethods.ExportUploaded, method);
  }
}
