import { Injectable } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IdentityService, NavigationService } from '../../../core/services';
import { AuthService } from './AuthService';
import { LocalStorageService } from '@cinetixx/cinetixx-ui';


@Injectable({
  providedIn: 'root'
})
export class LogAsService {

  public constructor(
    private readonly _authService: AuthService,
    private readonly _navigationService: NavigationService,
    private readonly _identityService: IdentityService,
    private readonly _localStorageService: LocalStorageService
  ) {}

  public logAs(token:string): Observable<boolean> {
     return this._authService.authWithToken(token)
       .pipe(
         map(identity => {
           this._localStorageService.clear();
           this._identityService.identity = identity;
           this._navigationService.navigateByUrl('/dashboard');

           return of(true);
         }),
         catchError(() => {
          this._identityService.destroyIdentity();
          this._navigationService.navigateByUrl('/auth/login');

          return of(false);
        })
       ) as Observable<boolean>;
  }
}
