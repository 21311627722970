import { Injectable, NgZone } from '@angular/core';
import { NavigationBehaviorOptions, Router, UrlTree } from '@angular/router';
import { LanguageStoreService } from '../../language/core/services';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  public constructor(
    private readonly _router: Router,
    private readonly _languageStoreService: LanguageStoreService,
    private readonly _zone: NgZone
  ) {}

  public navigateByUrl(url: string | UrlTree, extras?: NavigationBehaviorOptions) {
    const language = this._languageStoreService.language?.token || environment.fallbackLanguage;

    if (language && !url.toString().startsWith(`/${ language }`)) {
      url = `${ language }${ url }`;
    }

    this._zone.run(() => this._router.navigateByUrl(url, extras));
  }
}
