<router-outlet (activate)="isLoaderVisible = false"></router-outlet>

<div class="loader-container" *ngIf="isLoaderVisible">
  <img src="/assets/images/logos/cinetixx-logo.svg" alt="cinetixx" />
  <div class="loader">
    <div class="loader-bar"></div>
  </div>
</div>

<app-cookies
  colorBrand="#0d6ebc"
  link1Href="/de/page/impressum"
  link2Href="/de/page/datenschutz"
  link1Text="Impressum"
  link2Text="Datenschutzerklärung"
  moreInformationLinkHref="/de/page/datenschutz"
></app-cookies>

<cx-new-version-modal #newVersionModalComponent *ngIf="isNewVersionModalVisible"></cx-new-version-modal>
