import { Injectable } from '@angular/core';
import { ISnippet } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class SnippetsStoreService {

  private _snippets: ISnippet[] = [];

  public get snippets(): ISnippet[] {
    return this._snippets;
  }

  public set snippets(snippets: ISnippet[]) {
    this._snippets = snippets;
  }
}
