import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { LanguageStoreService } from '../../language/core/services';
import { environment } from '../../../environments/environment';


export const NoLangGuard: CanActivateFn = (): boolean => {
  location.href = `/${ inject(LanguageStoreService).language?.token ?? environment.fallbackLanguage }`;

  return true;
};
