import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IEmployee } from '../../employees/core/interfaces';


@Injectable({
  providedIn: 'root'
})
export class CurrentUserStoreService {

  public readonly employee$ = new Subject<IEmployee>();

  private _employee: IEmployee = null;

  public get currentUser(): IEmployee {
    return this._employee;
  }

  public set currentUser(user: IEmployee) {
    this.employee$.next(user);
    this._employee = user;
  }
}
