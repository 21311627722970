import moment from 'moment/moment';

export {};

Date.getCinemaDaysShortForm = (): string[] => {
  const daysOfTheWeek = moment.weekdaysShort();
  daysOfTheWeek.push(...daysOfTheWeek.splice(0, 4));

  return daysOfTheWeek;
};
