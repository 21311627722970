export {};

Number.prototype.toNotation = function(): string {
  return this.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

Number.prototype.toEuro = function(withOptions = true, minimumFractionDigits = 2, maximumFractionDigits = 2): string {
  return withOptions
    ? `${ this.toLocaleString('de-DE', { minimumFractionDigits, maximumFractionDigits }) } €`
    : `${ this.toString().replace('.', ',') } €`;
};

Number.prototype.toPercent = function(): string {
  return `${ this.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) } %`;
};
