export enum Countries {
  DE = 'Deutschland',
  EN = 'England',
  FR = 'Frankreich',
  IT = 'Italien',
  SCH = 'Schweiz',
  SP = 'Spanien',
  US = 'USA',
  OS = 'Österreich'
}
