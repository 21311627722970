import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { IdentityService, NavigationService } from '../services';

export const NoAuthGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
  if (!inject(IdentityService).identity || state.url.includes('auth/logout')) {
    return true;
  } else {
    inject(NavigationService).navigateByUrl('/dashboard');

    return false;
  }
};
