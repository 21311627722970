import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private readonly _httpClient: HttpClient
  ) {}

  public get(path: string, params: HttpParams | {} = new HttpParams()): Observable<any> {
    return this._httpClient.get(`${ environment.apiUrl }${ path }`, { params });
  }

  public post(
    path: string,
    body: Object = {},
    params: HttpParams = new HttpParams(),
    responseType?: string,
    observe: 'body' = 'body'
  ): Observable<any> {
    const options = {
      params: params,
      observe: observe,
      headers: {
        'Content-Type': 'application/json'
      },
    };

    if (responseType) {
      options['responseType'] = responseType;
    }

    return this._httpClient.post(`${ environment.apiUrl }${ path }`, JSON.stringify(body), options);
  }

  public postFormData(path: string, formData: FormData, params: HttpParams = new HttpParams()): Observable<any> {
    return this._httpClient.post(
      `${ environment.apiUrl }${ path }`,
      formData,
      {
        params,
      }
    );
  }

  public uploadFile(path: string, formData: FormData, params: HttpParams = new HttpParams()): Observable<any> {
    return this._httpClient.post(
      `${ environment.apiUrl }${ path }`,
      formData,
      {
        params,
        observe: 'events',
        reportProgress: true
      }
    );
  }

  public uploadFileToPredefinedUrl(url: string, file: File, params: HttpParams = new HttpParams()): Observable<any> {
    return this._httpClient.put(url, file, {
      params,
      observe: 'events',
      reportProgress: true,
      headers: {
        'Content-Type': file.type,
        'x-amz-acl': 'public-read',
      }
    });
  }

  public getFile(path: string, contentType: string, params: HttpParams = new HttpParams()): Observable<Blob> {
    return this._httpClient.get(`${ environment.apiUrl }${ path }`,
      {
        params,
        headers: {
          'Accept': contentType
        },
        responseType : 'blob',
      },
    );
  }

  public put(path: string, body = {}): Observable<any> {
    return this._httpClient.put(
      `${ environment.apiUrl }${ path }`,
      JSON.stringify(body),
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
  }

  public patch(path: string, body = {}): Observable<any> {
    return this._httpClient.patch(
      `${ environment.apiUrl }${ path }`,
      JSON.stringify(body),
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
  }

  public delete(path: string, body = {}): Observable<any> {
    return this._httpClient.delete(`${ environment.apiUrl }${ path }`, { body });
  }
}
