import { ApiService } from './ApiService';
import { Observable } from 'rxjs';
import { Filter, IPagination, Search, SortingTypes } from '@cinetixx/cinetixx-ui';
import { HttpUtils } from '../utils';
import { inject } from '@angular/core';

export abstract class AbstractRestService<T> {

  protected readonly abstract basePath: string;
  protected readonly apiService = inject(ApiService);

  public getAll(): Observable<T[]> {
    return this.apiService.get(`${ this.basePath }/all`);
  }

  public getPaginatedAll(
    page: number,
    results: number,
    sortField: string,
    sortOrder: SortingTypes,
    search: Search = [],
    filter: Filter = []
  ): Observable<IPagination<T>> {
    const params = HttpUtils.prepareQueryableParams(page, results, sortField, sortOrder, search, filter);

    return this.apiService.get(`${ this.basePath }`, params);
  }

  public getOne(id: number): Observable<T> {
    return this.apiService.get(`${ this.basePath }/${ id }`);
  }

  public create(obj: T): Observable<T> {
    return this.apiService.post(`${ this.basePath }`, obj);
  }

  public update(id: number, obj: T): Observable<T> {
    return this.apiService.put(`${ this.basePath }/${ id }`, obj);
  }

  public delete(id: number): Observable<void> {
    return this.apiService.delete(`${ this.basePath }/${ id }`);
  }
}
