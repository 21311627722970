import { ActivatedRouteSnapshot, CanDeactivateFn, RouterStateSnapshot, } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { AbstractBaseForm, LeaveConfirmationModalComponent } from '@cinetixx/cinetixx-ui';

export const LeavePageGuard: CanDeactivateFn<any> = (
  component: any,
  currentRoute: ActivatedRouteSnapshot,
  currentState: RouterStateSnapshot,
  nextState?: RouterStateSnapshot,
): Observable<boolean> => {
  const canLeave$ = new Subject<boolean>();
  const closeModal = (): void => {
    canLeave$.next(true);
    LeaveConfirmationModalComponent.instance.close();
  };

  setTimeout(() => {
    const dirtyForms = AbstractBaseForm.instances.filter(x => x.form.dirty || x.forceShowLeaveConfirmationModal);
    let responsesCount: boolean[] = [];

    if (dirtyForms.length > 0) {
      LeaveConfirmationModalComponent.instance.resetState();
      LeaveConfirmationModalComponent.instance.onConfirm = () => {
        dirtyForms.forEach(x => {
          x.formErrorOccurred.subscribe(() => LeaveConfirmationModalComponent.instance.isLoading = false);

          x.submitting.subscribe(submitting => {
            if (!submitting) {
              responsesCount.push(!x.isError);

              if (responsesCount.length === dirtyForms.length) {
                LeaveConfirmationModalComponent.instance.isLoading = false;
              }

              if (!responsesCount.includes(false)) {
                closeModal();
                canLeave$.next(true);
              }
            }
          });

          x.onSubmit();
        });
      };

      LeaveConfirmationModalComponent.instance.onSecondaryButton = closeModal;

      LeaveConfirmationModalComponent.instance.closed.subscribe(() => canLeave$.next(false));

      LeaveConfirmationModalComponent.instance.open();
    } else {
      canLeave$.next(true);
    }
  });

  return canLeave$.asObservable();
};
