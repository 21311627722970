import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ITableConfig, ITableConfigStrategy } from '@cinetixx/cinetixx-ui';
import { UserPreferencesService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class TableConfigDatabaseStrategy<T> implements ITableConfigStrategy<T> {

  private readonly _keyPrefix = 'table-config';

  public constructor(
    private readonly _userPreferencesService: UserPreferencesService
  ) {
  }

  public getConfig(namespace: string): Observable<ITableConfig<T>> {
    return this._userPreferencesService.getPreferenceByKey(this.getKey(namespace));
  }

  public saveConfig(namespace: string, config: ITableConfig<T>): Observable<any> {
    return this._userPreferencesService.savePreferences(this.getKey(namespace), config);
  }

  private getKey(namespace: string): string {
    return `${this._keyPrefix}-${namespace}`;
  }
}
