import { cloneDeep } from 'lodash';

export {};

Array.prototype.sortByKey = function (key: string, sortingType: 'asc' | 'desc' = 'asc'): any[] {
  if (key && sortingType) {
    const sortedData = this.sort(
      (a: any, b: any) =>
        (typeof a[key] === 'string'
          ? cloneDeep(a[key])?.toUpperCase()
          : a[key]) < (typeof b[key] === 'string'
          ? cloneDeep(b[key])?.toUpperCase()
          : b[key]) ? -1 : 1
    );

    return sortingType === 'asc' ? sortedData : sortedData.reverse();
  } else {
    return this;
  }
};
