import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppVersionStoreService {

  private _version = environment.version;
  private _version$ = new BehaviorSubject<string>(this._version);

  public get version$(): Observable<string> {
    return this._version$.asObservable();
  }

  public get version(): string {
    return this._version;
  }

  public set version(version: string) {
    this._version = `${ environment.version }.${ version.replace('dev-', '') }`;
    this._version$.next(this._version);
  }
}
