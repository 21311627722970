import { BehaviorSubject, fromEvent, Subject, Subscription } from 'rxjs';
import { DeleteConfirmationDirective, ICinemaWeek, KwPipe, StaticDependenciesService, } from '@cinetixx/cinetixx-ui';
import { Calendar, DateInput, EventInput } from '@fullcalendar/core';
import { AreaSelectDirective } from '../directives';
import { Directive, ElementRef, inject } from '@angular/core';
import { LanguageStoreService } from '../../language/core/services';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { EventImpl } from '@fullcalendar/core/internal';
import { CalendarModes } from '../../planning/core/enums';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { EventDragStartArg } from '@fullcalendar/interaction';

@Directive()
export abstract class AbstractCalendarService {

  public currentPlanningCellHeight: number;
  public datesSet: () => void;
  public isPlanningLoading$ = new Subject<boolean>();
  public eventsChanges$ = new Subject<void>();
  public areaSelectDirectiveInit$ = new BehaviorSubject<boolean>(false);
  public fullCalendarEl: HTMLElement;
  public fullCalendarApi: Calendar;
  public areaSelectDirective: AreaSelectDirective;
  public areTooltipsHidden = false;
  public isFitToWindowActive = false;
  public readonly initialPlanningCellHeight = 8;

  protected timeGridColumns: HTMLTableCellElement[] = [];
  protected prevStartDifference = 0;
  protected currentDragEvent: EventDragStartArg;
  protected eventsToCopy: EventImpl[] = [];
  protected dragging$: Subscription;
  protected scrolling$: Subscription;
  protected selectedCalendarMode = CalendarModes.WEEK;
  protected isMouseInContainer = false;
  protected calendarWidthObserver: ResizeObserver;
  protected readonly locales = {
    'en': require('@fullcalendar/core/locales/en-gb'),
    'de': require('@fullcalendar/core/locales/de'),
  };
  protected readonly subs$: Subscription[] = [];

  protected readonly deleteConfirmationDirective = new DeleteConfirmationDirective();

  private _selectedEvents: EventImpl[] = [];
  private _selectedEventsMap: Map<string, Date> = new Map();
  private _fullCalendarComponent: FullCalendarComponent;
  private _scrollContainer: HTMLElement;
  private _styleEl: HTMLStyleElement;
  private _rowsCount: number;

  protected readonly renderer2 = StaticDependenciesService.renderer;
  protected readonly kwPipe = inject(KwPipe);
  protected readonly languageStoreService = inject(LanguageStoreService);

  protected constructor() {
    this.currentPlanningCellHeight = this.initialPlanningCellHeight;
  }

  public init(fullCalendarComponent: FullCalendarComponent, cb?: () => void): void {
    this._fullCalendarComponent = fullCalendarComponent;
    this.fullCalendarApi = this._fullCalendarComponent.getApi();

    setTimeout(() => {
      this.fullCalendarApi.render();
      this.fullCalendarEl = this.fullCalendarApi.el;
      this._scrollContainer = this.fullCalendarEl.querySelector('.fc-scroller-liquid-absolute');
      this._rowsCount = this.fullCalendarEl.querySelectorAll(
        '.fc-timegrid-slot.fc-timegrid-slot-lane'
      ).length;

      if (cb) {
        cb();
      }


      if (!('ontouchstart' in window)) {
        this.initAreaSelect();
      }

      this.observeCalendarWidth();
      this.copyEventsActions();
      this.queryTimeGridColumns();
    });
  }


  public queryTimeGridColumns(): void {
    this.timeGridColumns = Array.from(
      this.fullCalendarEl.querySelectorAll('.fc-day.fc-timegrid-col.fc-resource')
    ).filter(x => getComputedStyle(x).display !== 'none') as HTMLTableCellElement[];
  }

  public unselectSelectedEvents(): void {
    const selectedEvents = this.getSelectedEvents();

    for (let i = 0; i < selectedEvents.length; i++) {
      selectedEvents[i].setExtendedProp('isSelected', false);
      this.areaSelectDirective.deselect(
        this.fullCalendarEl.querySelector(`.${ selectedEvents[i].id }`),
        false
      );
    }
  }

  public setEventStart(event: EventImpl, date: DateInput): void {
    event.setStart(date, {
      maintainDuration: true,
    });
  }

  public triggerPlanningLoader(cb: () => void): void {
    this.isPlanningLoading$.next(true);

    setTimeout(() => {
      cb();
      this.isPlanningLoading$.next(false);
    });
  }

  public changeCalendarMode(mode: CalendarModes): void {
    this.selectedCalendarMode = mode;

    this.fullCalendarApi.changeView(
      this.selectedCalendarMode === CalendarModes.WEEK ? 'timeGridSevenDays' : 'timeGridOneDay'
    );
  }

  public hideLastDay(kw: ICinemaWeek, date: Date): void {
    const lastDate = moment(this.selectedCalendarMode === CalendarModes.WEEK ? kw.to : date);
    const fDate = lastDate.add('1', 'day').format('YYYY-MM-DD');

    if (this._styleEl) {
      this._styleEl.remove();
    }

    this._styleEl = this.renderer2.createElement('style') as HTMLStyleElement;

    //language=css
    this._styleEl.innerHTML = `
      .full-calendar td[data-date="${ fDate }"],
      .full-calendar th[data-date="${ fDate }"] {
        display: none;
      }
    `;

    this.renderer2.appendChild(StaticDependenciesService.document.head, this._styleEl);
  }

  public get scrollerEl(): HTMLDivElement {
    return this.fullCalendarEl.querySelector('.fc-scroller.fc-scroller-liquid-absolute');
  }

  public changeZoom(step: number): void {
    this.currentPlanningCellHeight += step;
    this.isFitToWindowActive = false;

    if (this.currentPlanningCellHeight <= this.planningCellHeightAfterFitToWindow) {
      this.fitToWindow();
    }
  }

  public fitToWindow(): void {
    this.currentPlanningCellHeight = this.planningCellHeightAfterFitToWindow;
    this.isFitToWindowActive = true;
  }

  public resetZoom(): void {
    this.currentPlanningCellHeight = this.initialPlanningCellHeight;
    this.isFitToWindowActive = this.currentPlanningCellHeight <= this.planningCellHeightAfterFitToWindow;
  }

  public eventsOverlapActions(): void {
    const events = this.fullCalendarApi.getEvents();
    const overlappedEvents = new Set<EventImpl>();

    for (let i = 0; i < events.length; i++) {
      for (let j = i + 1; j < events.length; j++) {
        const a = events[i];
        const b = events[j];

        if (this.isOverlapped(a, b)) {
          overlappedEvents.add(a);
          overlappedEvents.add(b);
        }
      }
    }

    for (const event of events) {
      if (overlappedEvents.has(event)) {
        event.setExtendedProp('overlap', true);
      } else if (event.extendedProps['overlap']) {
        event.setExtendedProp('overlap', false);
      }
    }
  }

  public getSelectedEvents(): EventImpl[] {
    return this._selectedEvents;
  }

  public static setEventLoadingState(event: EventImpl, loading: boolean): void {
    event.setExtendedProp('loading', loading);
  }

  protected copyEventsActions(): void {}

  protected onEventDragStart(): void {
    this.areaSelectDirective.selectionArea.cancel();
    this._selectedEvents = this.draggableSelectedEvents;

    if (!this.currentDragEvent.event.extendedProps['isSelected']) {
      const selectionClone = cloneDeep(this.areaSelectDirective.selectionArea.getSelection());

      for (let i = 0; i < selectionClone.length; i++) {
        this.areaSelectDirective.selectionArea.deselect(selectionClone[i]);
      }

      for (let i = 0; i < this._selectedEvents.length; i++) {
        this._selectedEvents[i].setExtendedProp('isSelected', false);
      }
    } else {
      this._selectedEventsMap = new Map(this._selectedEvents.map(x => [x.id, x.start]));
      this.dragging$ = fromEvent(this.fullCalendarEl, 'mousemove').subscribe(this.onDragging.bind(this));
      this.scrolling$ = fromEvent(this._scrollContainer, 'scroll').subscribe(this.onDragging.bind(this));
    }
  }

  protected onDragging(): void {
    const { start: originalStart, id } = this.currentDragEvent.event;
    const fGhostEl = this.fullCalendarEl.querySelector(
      `.fc .fc-event-dragging.${ id }`
    ) as HTMLElement;

    if (fGhostEl) {
      const fDay = fGhostEl.closest('td.fc-day');

      if (fDay) {
        const date = fDay.getAttribute('data-date');
        const range = JSON.parse(
          (fGhostEl.querySelector<HTMLElement>('.event-custom-content')).dataset['range']
        );
        const newStartDate = moment(`${ date } ${ moment(range.start).format('HH:mm') }`);
        const newEndDate = moment(`${ date } ${ moment(range.end).format('HH:mm') }`);

        if (newStartDate.hours() > -1 && newStartDate.hours() < 8) {
          newStartDate.add('1', 'day');
        }

        if (newEndDate.diff(newStartDate) < 0) {
          newEndDate.add('1', 'day');
        }

        const difference = newStartDate.diff(moment(originalStart));

        if (this.prevStartDifference !== difference) {
          this.prevStartDifference = difference;
          this.setSelectedEventsStart(start => moment(start).add(
            this.prevStartDifference, 'milliseconds').toDate()
          );
        }
      } else {
        this.setSelectedEventsStart(start => start);
      }
    }
  }

  protected getNewEvent(event: EventImpl): EventInput {
    return {};
  }

  protected copyEvent(event: EventImpl): void {
    const newEvent = this.getNewEvent(event);
    this.fullCalendarApi.addEvent(newEvent);
    this.copyEventApiCall(newEvent);
  }

  protected copyEventApiCall(event: EventInput): void {}

  protected getCopyWeekElByEventId(id: string): HTMLElement {
    return this.fullCalendarEl.querySelector<HTMLElement>(`#${ id } .copy-week-layer`);
  }

  protected observeCalendarWidth(): void {
    this.calendarWidthObserver = new ResizeObserver(() => this.fullCalendarApi.render());
    this.calendarWidthObserver.observe(this.fullCalendarEl);
  }

  protected get draggableSelectedEvents(): EventImpl[] {
    return this.getSelectedEvents();
  }

  private isOverlapped(a: EventImpl, b: EventImpl): boolean {
    const aStart = a.start as Date;
    const aEnd = a.end as Date;
    const bStart = b.start as Date;
    const bEnd = b.end as Date;

    return (
      a.getResources()[0]?.id === b.getResources()[0]?.id &&
      (
        (aStart < bEnd && aEnd > bStart) ||
        (bStart < aEnd && bEnd > aStart)
      )
    );
  }

  private setSelectedEventsStart(cb: (start: Date) => Date): void {
    for (let i = 0; i < this._selectedEvents.length; i++) {
      const event = this._selectedEvents[i];

      if (this.currentDragEvent.event.id !== event.id) {
        this.setEventStart(event, cb(this._selectedEventsMap.get(event.id)));
      }
    }
  }

  private get planningCellHeightAfterFitToWindow(): number {
    return (this.scrollerEl.offsetHeight - this._rowsCount) / this._rowsCount;
  }

  private initAreaSelect(): void {
    this.areaSelectDirective = new AreaSelectDirective(new ElementRef<HTMLElement>(this._scrollContainer));
    this.areaSelectDirective.boundaryClassName = 'fc-scroller-liquid-absolute';
    this.areaSelectDirective.selectableElementsSelector = 'selectable-event';
    this.areaSelectDirective.unselectElementsSelector = 'fc-timegrid-slot';
    this.areaSelectDirective.excludedClassesFromDrag = ['copy-week', 'loading'];
    this.areaSelectDirective.ngAfterViewInit();
    this.areaSelectDirectiveInit$.next(true);

    this.subs$.push(
      this.areaSelectDirective.onSelect.subscribe(x => {
        const events = this.fullCalendarApi.getEvents();

        for (let i = 0; i < events.length; i++) {
          const event = events[i];
          const { extendedProps: { isSelected } } = event;

          if (x.find(x => x.classList.contains(events[i].id))) {
            if (!isSelected) {
              event.setExtendedProp('isSelected', true);
            }
          } else {
            if (isSelected) {
              event.setExtendedProp('isSelected', false);
            }
          }
        }
      }),
      this.areaSelectDirective.loadingState.subscribe(this.isPlanningLoading$),
      this.areaSelectDirective.isMouseInContainer.subscribe(isMouseInContainer => {
        this.isMouseInContainer = isMouseInContainer;
      })
    );
  }

  public ngOnDestroy(): void {
    this.areaSelectDirective?.ngOnDestroy();
    this.calendarWidthObserver?.disconnect();

    for (let i = 0; i < this.subs$.length; i++) {
      this.subs$[i].unsubscribe();
    }
  }
}
