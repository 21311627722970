import { Injectable } from '@angular/core';
import { ILanguage } from '../interfaces';
import { LocalStorageService } from '@cinetixx/cinetixx-ui';

@Injectable({
  providedIn: 'root'
})
export class LanguageStoreService {

  private _language: ILanguage = null!;

  public constructor(
    private readonly _localStorageService: LocalStorageService
  ) {}

  public get language(): ILanguage {
    if (!this._language) {
      this._language = this._localStorageService.getItem('user-lang');
    }

    return this._language;
  }

  public set language(language: ILanguage) {
    this._localStorageService.setItem('user-lang', language);
    this._language = language;
  }
}
