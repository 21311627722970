import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LangUrlPipe, TranslatePipe } from './core/pipes';

@NgModule({
  declarations: [
    TranslatePipe,
    LangUrlPipe
  ],
  exports: [
    TranslatePipe,
    LangUrlPipe
  ],
  imports: [
    CommonModule,
  ]
})
export class LanguageModule { }
