import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Dictionaries, StoreCacheKeys } from '../enums';
import { Cache } from '../decorators';
import { IDictionary } from '../interfaces';
import { AbstractBaseDictionariesService } from './AbstractBaseDictionariesService';

@Injectable({
  providedIn: 'root'
})
export class DictionariesService extends AbstractBaseDictionariesService<Dictionaries> {

  protected readonly basePath = '/tools/dictionaries';

  @Cache<IDictionary>(StoreCacheKeys.GetGenres)
  public getGenres(): Observable<IDictionary> {
    return this.getDictionary(Dictionaries.Topics);
  }

  @Cache<IDictionary>(StoreCacheKeys.GetAgeRatings)
  public getAgeRatings(): Observable<IDictionary> {
    return this.getDictionary(Dictionaries.AgeRatings);
  }

  @Cache<IDictionary>(StoreCacheKeys.GetVersionTypes)
  public getVersionTypes(): Observable<IDictionary> {
    return this.getDictionary(Dictionaries.MovieVersionTypes);
  }

  @Cache<IDictionary>(StoreCacheKeys.GetCastTypes)
  public getCastTypes(): Observable<IDictionary> {
    return this.getDictionary(Dictionaries.CastTypes);
  }

  @Cache<IDictionary>(StoreCacheKeys.CountriesMovie)
  public getCountriesMovie(): Observable<IDictionary> {
    return this.getDictionary(Dictionaries.CountriesMovie);
  }

  @Cache<IDictionary>(StoreCacheKeys.GetMovieTypes)
  public getMovieTypes(): Observable<IDictionary> {
    return this.getDictionary(Dictionaries.MovieTypes);
  }

  @Cache<IDictionary>(StoreCacheKeys.GetSalesTax)
  public getSalesTax(): Observable<IDictionary> {
    return this.getDictionary(Dictionaries.SalesTax);
  }

  @Cache<IDictionary>(StoreCacheKeys.GetCountries)
  public getCountries(): Observable<IDictionary> {
    return this.getDictionary(Dictionaries.Countries);
  }

  @Cache<IDictionary>(StoreCacheKeys.GetLanguages)
  public getLanguages(): Observable<IDictionary> {
    return this.getDictionary(Dictionaries.Languages);
  }

  @Cache<IDictionary>(StoreCacheKeys.GetKioskActionsTypes)
  public getKioskActionsTypes(): Observable<IDictionary> {
    return this.getDictionary(Dictionaries.KioskActionsTypes);
  }

  @Cache<IDictionary>(StoreCacheKeys.GetTerminalTypes)
  public getTerminalTypes(): Observable<IDictionary> {
    return this.getDictionary(Dictionaries.TerminalTypes);
  }

  @Cache<IDictionary>(StoreCacheKeys.GetKioskLayoutTypes)
  public getKioskLayoutTypes(): Observable<IDictionary> {
    return this.getDictionary(Dictionaries.KioskLayoutTypes);
  }

  @Cache<IDictionary>(StoreCacheKeys.GetDistributors)
  public getDistributors(): Observable<IDictionary> {
    return this.getDictionary(Dictionaries.Distributors);
  }
}
