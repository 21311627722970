export enum PlanningWSMethods {
  ShowAdded = 'ShowAdded',
  ShowTimeChanged = 'ShowTimeChanged',
  TimeRestrictionsChanged = 'TimeRestrictionsChanged',
  SeatConfigurationChanged = 'SeatConfigurationChanged',
  PricesChanged = 'PricesChanged',
  ShowEnabled = 'ShowEnabled',
  ShowCanceled = 'ShowCanceled',
  ShowDeleted = 'ShowDeleted',
  AreaConfigurationChanged = 'AreaConfigurationChanged',
  TemplateChanged = 'TemplateChanged',
  ShowDetailsChanged = 'ShowDetailsChanged'
}
