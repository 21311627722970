import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MandatorDictionaries, StoreCacheKeys } from '../enums';
import { Cache } from '../decorators';
import { IDictionary } from '../interfaces';
import { AbstractBaseDictionariesService } from './AbstractBaseDictionariesService';

@Injectable({
  providedIn: 'root'
})
export class MandatorDictionariesService extends AbstractBaseDictionariesService<MandatorDictionaries> {

  protected readonly basePath = '/tools/dictionaries/mandator-dictionaries';

  @Cache<IDictionary>(StoreCacheKeys.GetCurrencies)
  public getCurrencies(): Observable<IDictionary> {
    return this.getDictionary(MandatorDictionaries.Currencies);
  }

  @Cache<IDictionary>(StoreCacheKeys.TabPriceNames)
  public getTabPriceNames(): Observable<IDictionary> {
    return this.getDictionary(MandatorDictionaries.TabPriceNames);
  }

  @Cache<IDictionary>(StoreCacheKeys.CardTypes)
  public getCardTypes(): Observable<IDictionary> {
    return this.getDictionary(MandatorDictionaries.CardTypes);
  }

  @Cache<IDictionary>(StoreCacheKeys.GetVoucherNames)
  public getTabVouchersNames(): Observable<IDictionary> {
    return this.getDictionary(MandatorDictionaries.TabVoucherNames);
  }

  @Cache<IDictionary>(StoreCacheKeys.GetTabCardsNames)
  public getTabCardsNames(): Observable<IDictionary> {
    return this.getDictionary(MandatorDictionaries.TabCardsNames);
  }

  @Cache<IDictionary>(StoreCacheKeys.GetKiosks)
  public getKiosks(): Observable<IDictionary> {
    return this.getDictionary(MandatorDictionaries.Kiosks);
  }

  @Cache<IDictionary>(StoreCacheKeys.GetKiosksProductGroup)
  public getKiosksProductGroup(): Observable<IDictionary> {
    return this.getDictionary(MandatorDictionaries.KioskProductGroup);
  }

  @Cache<IDictionary>(StoreCacheKeys.GetKiosksPriceCategories)
  public getKiosksPriceCategories(): Observable<IDictionary> {
    return this.getDictionary(MandatorDictionaries.KioskPriceCategories);
  }

  @Cache<IDictionary>(StoreCacheKeys.GetTicketFees)
  public getTicketFees(): Observable<IDictionary> {
    return this.getDictionary(MandatorDictionaries.TicketFees);
  }
}
